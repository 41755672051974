import React, { useEffect, useState } from "react";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../Loader";
import { fetchLeaveRequests } from "../ReuseableAPI";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const LeaveRequestForm = () => {
  const url = process.env.REACT_APP_API_URL;
  const empId = localStorage.getItem("_id");
  const token = localStorage.getItem("token");
  const [collegues, setCollegues] = useState([]);
  const [error, setError] = useState("");
  const [isShowPeriodOfLeave, setIsShowPeriodOfLeave] = useState(false);
  const navigate = useNavigate();
  const [typeOfLeave, setTypOfLeave] = useState(null);
  const [excludedDates, setExcludeDates] = useState([]);

  let leaveObj = {
    leaveType: "",
    fromDate: "",
    toDate: "",
    reasonForLeave: "",
    prescription: "",
    periodOfLeave: "full time",
    coverBy: ""
  };

  let leaveObjValidation = Yup.object().shape({
    leaveType: Yup.string().required("Leave type is required!"),
    fromDate: Yup.date()
      .min(new Date(), "You can select a date from tomorrow")
      .required("From Date is required")
      .test(
        "weekend check",
        "Weekends are not allowed",
        (value) => {
          if (value) {
            const day = new Date(value).getDay();
            return day !== 0 && day !== 6;
          }
          return true;
        }
      ),
    toDate: Yup.date().min(new Date(), "You can select date from Tomorrow")
      .test("check weekend",
        "Weekend is not allowed",
        (date) => {
          if (date) {
            const day = new Date(date).getDay();
            return day !== 0 && day !== 6;
          }
          return true;
        }
      )
      .required("ToDate is required"),
    reasonForLeave: Yup.string().required("Reason for Leave is required"),
    periodOfLeave: Yup.string().notRequired(),
    prescription: Yup.string().notRequired(),
    coverBy: Yup.string().notRequired()
  });

  const formik = useFormik({
    initialValues: leaveObj,
    validationSchema: leaveObjValidation,
    validateOnChange: true,
    onSubmit: async (values, { resetForm }) => {
      if (error === "") {
        try {
          // Leave request submission
          const res = await axios.post(`${url}/api/leave-application/${empId}`, values, {
            headers: {
              authorization: token || "",
            },
          });
          toast.success(res.data.message);
          resetForm();
          navigate(-1); // Navigate back
        } catch (err) {
          toast.error(err?.response?.data?.message);
          console.log(err);
        }
      }
    },
  });

  useEffect(() => {
    if (formik.values.fromDate && formik.values.toDate) {
      let fromDateTime = new Date(formik.values.fromDate).getTime();
      let toDateTime = new Date(formik.values.toDate).getTime();
      if (fromDateTime > toDateTime) {
        return setError("Please select next start date");
      } else if (new Date(formik.values.fromDate).getTime() === new Date(formik.values.toDate).getTime()) {
        return setIsShowPeriodOfLeave(true);
      } else {
        setIsShowPeriodOfLeave(false)
        setError("");
      }
    }
  }, [formik.values.fromDate, formik.values.toDate]);

  useEffect(() => {
    const gettingLeaveRequests = async () => {
      try {
        if (empId) {
          const leaveReqs = await fetchLeaveRequests(empId);

          const fromDates = leaveReqs?.peopleLeaveOnMonth.map((leave) => new Date(leave.fromDate));
          const toDates = leaveReqs?.peopleLeaveOnMonth.map((leave) => new Date(leave.toDate));
          setExcludeDates([...fromDates, ...toDates]);

          // Set types of leave
          setTypOfLeave(leaveReqs?.requests?.typesOfLeaveCount);

          // Filter colleagues
          const emps = leaveReqs.collegues.filter((emp) => emp._id !== empId);
          setCollegues(emps);
        } else {
          toast.error("empId is not loaded in the app.");
        }
      } catch (error) {
        console.error("Error fetching leave requests:", error);
        toast.error("Failed to fetch leave requests. Please try again.");
      }
    };

    gettingLeaveRequests();
  }, [empId]);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append('profile', file);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const filename = response.data.file.filename;
        formik.setFieldValue("prescription", filename)

      } catch (error) {
        console.error("Error uploading file:", error.response.data.message);
      }
    }
  };

  return typeOfLeave ? (
    <form onSubmit={formik.handleSubmit}>
      <div className="leaveFormContainer">
        <div className="leaveFormParent" style={{ width: "600px" }}>
          <div className="heading">
            <h5 className="my-3">
              <LibraryBooksIcon /> Leave Request Form
            </h5>
            <p className="text-dark">Fill the required fields below to apply for annual leave</p>
          </div>

          {/* Leave Type */}
          <div className="my-3">
            <span className="inputLabel">Leave Type</span>
            <select
              name="leaveType"
              className={`selectInput ${formik.touched.leaveType && formik.errors.leaveType ? "error" : ""}`}
              onChange={formik.handleChange}
              value={formik.values.leaveType}
            >
              <option>Select Leave type</option>
              {Object.entries(typeOfLeave).map((data) => {
                return <option value={`${data[0]} leave`}>{data[0].charAt(0).toUpperCase() + data[0].slice(1)} Leave</option>;
              })}
            </select>
            {formik.touched.leaveType && formik.errors.leaveType ? (
              <div className="text-center text-danger">{formik.errors.leaveType}</div>
            ) : null}
          </div>

          {/* Date Picker */}
          <div className="row my-3">
            <div className="col-12 col-lg-6 col-md-6">
              <span className="inputLabel">Start Date</span>
              <DatePicker
                className={`inputField ${formik.touched.fromDate && formik.errors.fromDate ? "error" : ""}`}
                selected={formik.values.fromDate}
                onChange={(date) => formik.setFieldValue("fromDate", date.toISOString().split("T")[0])}
                minDate={new Date()}
                excludeDates={excludedDates}
              />
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <div className="text-center text-danger">{formik.errors.fromDate}</div>
              ) : null}
            </div>
            <div className="col-12 col-lg-6 col-md-6">
              <span className="inputLabel">End Date</span>
              <DatePicker
                className={`inputField ${formik.touched.toDate && formik.errors.toDate ? "error" : ""}`}
                selected={formik.values.toDate}
                onChange={(date) => formik.setFieldValue("toDate", date.toISOString().split("T")[0])}
                minDate={new Date()}
                excludeDates={excludedDates}
              />
              {formik.errors.toDate && formik.touched.toDate ? (
                <div className="text-center text-danger">{formik.errors.toDate}</div>
              ) : error && <div className="text-center text-danger">{error}</div>}
            </div>
          </div>

          {/* Period of Leave */}
          {isShowPeriodOfLeave && (
            <div className="my-3">
              <span className="inputLabel">Period Of Leave</span>
              <select
                name="periodOfLeave"
                className="selectInput"
                onChange={formik.handleChange}
                value={formik.values.periodOfLeave}
              >
                <option>Select Leave Period</option>
                <option value="full day">Full Day</option>
                <option value="half day">Half Day</option>
              </select>
            </div>
          )}

          {/* Reason for Leave */}
          <div className="my-3">
            <span className="inputLabel">Reason for Leave</span>
            <input
              type="text"
              name="reasonForLeave"
              className={`inputField ${formik.touched.reasonForLeave && formik.errors.reasonForLeave ? "error" : ""}`}
              onChange={formik.handleChange}
              value={formik.values.reasonForLeave}
            />
            {formik.touched.reasonForLeave && formik.errors.reasonForLeave ? (
              <div className="text-center text-danger">{formik.errors.reasonForLeave}</div>
            ) : null}
          </div>

          {/* Attach file */}
          <div className="my-3">
            <span className="inputLabel">Attach handover document (pdf, jpg, docx or any other format)</span>
            <input
              type="file"
              name="prescription"
              className="fileInput"
              onChange={(e) => handleFileChange(e)} // Set the actual file, not just the name
            />
          </div>

          {/* Select Relief Officer */}
          <div className="my-3">
            <span className="inputLabel">Choose Relief Officer</span>
            <select
              name="coverBy"
              className="selectInput"
              onChange={formik.handleChange}
              value={formik.values.coverBy}
            >
              <option>Select a Relief Officer</option>
              {collegues.map((emp) => (
                <option value={emp._id}>{emp.FirstName}</option>
              ))}
            </select>
          </div>

          {/* Action buttons */}
          <div className="row gap-2 d-flex align-items-center justify-content-center my-4">
            <div className="col-12 col-lg-5 col-md-5">
              <button
                type="button"
                className="btn btn-outline-dark w-100"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
            </div>
            <div className="col-12 col-lg-5 my-2 col-md-5">
              <button type="submit" className="btn btn-dark w-100">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <Loading />
  );
};

export default LeaveRequestForm;
