// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modelBackground {
  background-color: rgb(0 0 0 / 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

.modelContent {
  padding: 10px;
  /* width: fit-content; */
  width: 400px;
  background-color: white;
  border-radius: 6px;
}

.modelInput {
  display: block;
  width: 100%;
  margin: 10px 0px;
}

.inputFont {
  color: gray;
  font-size: 17px;
  font-weight: 600;
}

.outlineDesign {
  border: 2px solid #ced4da;
  padding: 4px 25px;
  border-radius: 4px;
}

.arrow {
  transition: transform 0.3s ease !important;
  display: inline-block !important;
  cursor: pointer;
}

.rotate {
  transform: rotate(180deg);
}

`, "",{"version":3,"sources":["webpack://./src/components/payslip/editModel.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA;EACE,aAAa;EACb,wBAAwB;EACxB,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;EAC1C,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".modelBackground {\n  background-color: rgb(0 0 0 / 0.5);\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.modelContent {\n  padding: 10px;\n  /* width: fit-content; */\n  width: 400px;\n  background-color: white;\n  border-radius: 6px;\n}\n\n.modelInput {\n  display: block;\n  width: 100%;\n  margin: 10px 0px;\n}\n\n.inputFont {\n  color: gray;\n  font-size: 17px;\n  font-weight: 600;\n}\n\n.outlineDesign {\n  border: 2px solid #ced4da;\n  padding: 4px 25px;\n  border-radius: 4px;\n}\n\n.arrow {\n  transition: transform 0.3s ease !important;\n  display: inline-block !important;\n  cursor: pointer;\n}\n\n.rotate {\n  transform: rotate(180deg);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
