// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payslipHeader {
  padding: 0px 5px 15px 5px;
  border-bottom: 1px dotted black;
  justify-content: space-between;
}
.brightLogo {
  height: 75px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: orange;
  font-size: 60px;
  font-weight: 900;
  color: #ffff;
}

.boxBorder {
  border: 2px double black;
}
.boxBorder > div {
  border-bottom: 2px double;
  padding: 10px 30px;
  text-align: center;
}

.payslipTxt {
  font-size: 12px;
  color: gray;
}

.rupeeFont {
  font-size: 20px;
  color: green;
  font-weight: 900;
}

.totalBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: greenyellow;
  padding: 0px 20px;
  font-size: 15px;
  font-weight: 600;
  height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/payslip/payslipui.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,8BAA8B;AAChC;AACA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".payslipHeader {\n  padding: 0px 5px 15px 5px;\n  border-bottom: 1px dotted black;\n  justify-content: space-between;\n}\n.brightLogo {\n  height: 75px;\n  width: 75px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: orange;\n  font-size: 60px;\n  font-weight: 900;\n  color: #ffff;\n}\n\n.boxBorder {\n  border: 2px double black;\n}\n.boxBorder > div {\n  border-bottom: 2px double;\n  padding: 10px 30px;\n  text-align: center;\n}\n\n.payslipTxt {\n  font-size: 12px;\n  color: gray;\n}\n\n.rupeeFont {\n  font-size: 20px;\n  color: green;\n  font-weight: 900;\n}\n\n.totalBox {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: greenyellow;\n  padding: 0px 20px;\n  font-size: 15px;\n  font-weight: 600;\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
