// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-card {
    border: 2px solid rgba(237, 237, 237, 1);
    border-radius: 8px;
}
.summary-card h3 {
    font-size: 24px;
    margin-bottom: 5px;
}
.summary-card p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(158, 158, 158, 1);

}
.summary-divider {
    border-left: 1px solid #e0e0e0;
    height: 90%;
    margin: 4px 10px;
}
.summary-card .numvalue
{
font-family: Inter;
font-size:25px;
font-weight: 500;
line-height: 36px;
letter-spacing: -0.021em;
color: rgba(64, 64, 64, 1);

}`, "",{"version":3,"sources":["webpack://./src/components/attendance/Summary.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,6BAA6B;;AAEjC;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,gBAAgB;AACpB;AACA;;AAEA,kBAAkB;AAClB,cAAc;AACd,gBAAgB;AAChB,iBAAiB;AACjB,wBAAwB;AACxB,0BAA0B;;AAE1B","sourcesContent":[".summary-card {\n    border: 2px solid rgba(237, 237, 237, 1);\n    border-radius: 8px;\n}\n.summary-card h3 {\n    font-size: 24px;\n    margin-bottom: 5px;\n}\n.summary-card p {\n    font-family: Inter;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 18px;\n    text-align: left;\n    color: rgba(158, 158, 158, 1);\n\n}\n.summary-divider {\n    border-left: 1px solid #e0e0e0;\n    height: 90%;\n    margin: 4px 10px;\n}\n.summary-card .numvalue\n{\nfont-family: Inter;\nfont-size:25px;\nfont-weight: 500;\nline-height: 36px;\nletter-spacing: -0.021em;\ncolor: rgba(64, 64, 64, 1);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
