// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#root {
  height: auto;
}

#main-outer-div {
  position: relative;
  background-color: #fff;
  width: 80%;
  max-width: 400px;
  margin: auto;
  margin-top: 7%;
  padding: 30px;
  border-radius: 10px;
}


#logo-img {
  width: 100%;
  margin: 10% auto 10%;
}

#logo-div {
  width: 50%;
  margin: 0 auto;
}

#title-div {
  margin: 0 auto;
  text-align: center;
}

 .login-form-input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  margin: 10px 0 10px;
  padding: 15px;
  border: 1px;
  background-color: #eef3ff;
}

.login-form-input:focus {
  outline: 0;
}

#outer-login-form-div {
  width: 90%;
  margin: 0 auto;
}
.login-form-input[type="submit"] {
  background-color: #395faf;
  border-width: 0px;
  margin-top: 15px;
  padding: 0px;
} 

.loading {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-size: 20px;
}

.alert {
  font-size: 14px;
  text-align: center;
  color: red;
}

@media (max-width: 300px) {
  #main-outer-div {
    width: 50%;
    min-width: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":";AACA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;AACrB;;;AAGA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;CAEC;EACC,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;IACV,cAAc;EAChB;AACF","sourcesContent":["\n#root {\n  height: auto;\n}\n\n#main-outer-div {\n  position: relative;\n  background-color: #fff;\n  width: 80%;\n  max-width: 400px;\n  margin: auto;\n  margin-top: 7%;\n  padding: 30px;\n  border-radius: 10px;\n}\n\n\n#logo-img {\n  width: 100%;\n  margin: 10% auto 10%;\n}\n\n#logo-div {\n  width: 50%;\n  margin: 0 auto;\n}\n\n#title-div {\n  margin: 0 auto;\n  text-align: center;\n}\n\n .login-form-input {\n  box-sizing: border-box;\n  width: 100%;\n  border-radius: 10px;\n  height: 40px;\n  margin: 10px 0 10px;\n  padding: 15px;\n  border: 1px;\n  background-color: #eef3ff;\n}\n\n.login-form-input:focus {\n  outline: 0;\n}\n\n#outer-login-form-div {\n  width: 90%;\n  margin: 0 auto;\n}\n.login-form-input[type=\"submit\"] {\n  background-color: #395faf;\n  border-width: 0px;\n  margin-top: 15px;\n  padding: 0px;\n} \n\n.loading {\n  text-align: center;\n  margin-top: 15px;\n}\n\n.title {\n  font-size: 20px;\n}\n\n.alert {\n  font-size: 14px;\n  text-align: center;\n  color: red;\n}\n\n@media (max-width: 300px) {\n  #main-outer-div {\n    width: 50%;\n    min-width: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
