// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webnxs {
    background: white;
    padding: 4px 3px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 1000;
    height: var(--header-height);
}

.sidebarIcon{
    display: none;
}

@media (max-width: 450px) {
    .sidebarIcon{
        display: flex;
    }
}

.organization_logo {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

/* .searchParent {
    display: flex;
    position: relative;
    width: fit-content;
}

.searchParent>input {
    width: 300px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding: 0px 10px;
}

.searchicon {
    position: absolute;
    right: 10px;
    top: 7px;
}

.searchlogo {
    width: 25px;
    height: 20px;
    object-fit: contain;

} */

/* .avatar {
    width: 44px;
    height: 44px;
    border: none;
    padding: 0px;
    margin: 0;
} */`, "",{"version":3,"sources":["webpack://./src/components/payslip/layout/navbar.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,QAAQ;IACR,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;GAyBG;;AAEH;;;;;;GAMG","sourcesContent":[".webnxs {\n    background: white;\n    padding: 4px 3px;\n    position: fixed;\n    width: 100%;\n    top: 0px;\n    z-index: 1000;\n    height: var(--header-height);\n}\n\n.sidebarIcon{\n    display: none;\n}\n\n@media (max-width: 450px) {\n    .sidebarIcon{\n        display: flex;\n    }\n}\n\n.organization_logo {\n    width: 30px;\n    height: 30px;\n    object-fit: cover;\n}\n\n/* .searchParent {\n    display: flex;\n    position: relative;\n    width: fit-content;\n}\n\n.searchParent>input {\n    width: 300px;\n    height: 40px;\n    border: none;\n    border-radius: 10px;\n    padding: 0px 10px;\n}\n\n.searchicon {\n    position: absolute;\n    right: 10px;\n    top: 7px;\n}\n\n.searchlogo {\n    width: 25px;\n    height: 20px;\n    object-fit: contain;\n\n} */\n\n/* .avatar {\n    width: 44px;\n    height: 44px;\n    border: none;\n    padding: 0px;\n    margin: 0;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
